import Body from "../components/Body";

export default function AboutPage() {

    return (<Body sidebar>
        <h1>About</h1>
        <img src={require('../images/melanie_profile.webp')} width={250} height={250} alt={""}/>
        <p>Melanie is a registered
            psychologist who is passionate about helping her clients to better understand and experience themselves in a
            way that creates a greater sense of connection, meaning and well-being in their
            lives. </p>
        Melanie draws on a range of evidence-based therapies to tailor an approach suited to her client’s individual
        needs, including:
        <ul>
            <li>Cognitive Behaviour Therapy (CBT)</li>
            <li>Acceptance and Commitment Therapy (ACT)</li>
            <li>Dialectical Behaviour Therapy (DBT)</li>
            <li>Schema Therapy</li>
        </ul>
        She has experience working with individuals experiencing:
        <ul>
            <li>depression</li>
            <li>stress</li>
            <li>anxiety disorders</li>
            <li>bipolar</li>
            <li>relationship issues</li>
            <li>emotional dysregulation</li>
            <li>anger</li>
            <li>grief and loss</li>
            <li>work issues</li>
            <li>trauma</li>
            <li>borderline personality disorder</li>
            <li>sleep difficulties</li>
        </ul>
        <p>Mel is also a STAP-accredited and board-approved psychology supervisor and has is able to provide
            supervision.</p>
        <p>Melanie enjoys working with adults, children and adolescents. She is also an animal lover and has a keen
            interest in the therapeutic influence they have on people.</p>
    </Body>)
}
