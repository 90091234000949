import Body from "../components/Body";

export default function BlogPage() {

    return (<Body sidebar>
        <h1>Blog</h1>
        <p>Melanie has written some blog posts including:</p>
        <ul>
            <li> <a href="https://www.truenorthpsychology.com.au/single-post/what-to-expect-in-your-first-psychology-session">What to expect in your first psychology session</a></li>
            <li> <a href="https://www.truenorthpsychology.com.au/single-post/polyvagal-theory">Polyvagal Theory</a></li>
        </ul>

    </Body>)
}
