import Body from "../components/Body";
import Button from "react-bootstrap/Button";

export default function HomePage() {

    return (<Body sidebar>
        <h1>Melanie Green Psychology</h1>
        <p>Melanie is a Brisbane based registered psychologist providing service to the inner northern suburbs. </p>
        <p>Melanie currently works Thursday at True North Psychology </p>
        {/*<p>Appointments can be booked either by phone or online at:</p>*/}
        <p>Appointments can be booked by phone at:</p>
        <>True North Psychology: </>
        {/*<Button type="button">Book Online</Button>*/}
        <Button type="button" class="btn btn-light" href="tel:0738560004">PH: (07) 3856 0004</Button>

    </Body>)
}
